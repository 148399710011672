<template>
  <div class="container">
    <div class="content">
      <div class="title">Edit Entry</div>
      <el-form ref="form">
        <div class="loginInputView">
          <el-form-item prop="name"
            ><el-input v-model="form.name" placeholder="Entry Title"></el-input
          ></el-form-item>
        </div>
        <div class="loginInputView" v-if="$route.query.program!=='PIE' && $route.query.program!=='POE'">
          <el-form-item>
            <el-select
              v-model="form.category"
              placeholder="Category"
              style="width: 320px"
              clearable
            >
              <el-option
                v-for="item in categories"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- <div class="loginInputView">
          <el-form-item prop="role"
            ><el-input v-model="form.role" placeholder="Role"></el-input
          ></el-form-item>
        </div> -->
        <div class="loginInputView" style="width: 100%">
          <el-form-item prop="intro"
            ><el-input
              v-model="form.intro"
              type="textarea"
              placeholder="A Brief Introduction for Your Entry"
              :rows="4"
            ></el-input
          ></el-form-item>
        </div>
        <div style="margin-top: 20px">Upload Cover Image for Your Entry</div>

        <el-upload
          action="/"
          style="margin-top: 20px"
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="form.cover_pic"
            :src="form.cover_pic"
            style="width: 150px;height: 150px; border-radius: 10px"
          />
          <img v-else-if="form.program==='MR'" src="../../assets/pic.png" style="width: 150px;height: 150px;" />
          <img v-else-if="form.program==='PIE'" src="../../assets/piePic.png" style="width: 150px;height: 150px;" />
          <img v-else-if="form.program==='POE'" src="../../assets/poePic.png" style="width: 150px;height: 150px;" />
        </el-upload>
      </el-form>

      <div class="confirm" @click="confirmForm">Confirm</div>
    </div>
  </div>
</template>

<script>
import { updateEntry, getQNToken, getMREntry } from '../../api/index'
import * as qiniu from 'qiniu-js'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      id: this.$route.query.id,
      form: {},
      categories: [
        'Campus Magazine & Publication',
        'Campus Newspaper',
        'Yearbook',
        'Video',
        'Campus Short Film',
        'Photograph',
        'Event Promotion',
        'WeChat Official Account',
        'Video Account',
        'Comic Work',
        'Design Work',
        'Handicraft',
        'Sculpture',
        'Painting',
        'Picture Book',
        'Calligraphy',
        'Website'
      ]
    }
  },
  mounted () {
    if (this.id) {
      getMREntry(this.id).then((res) => {
        if (res.data.code === 0) {
          this.form = res.data.data
        }
      })
    }
  },
  methods: {
    confirmForm () {
      updateEntry({ ...this.form, entry_id: this.form._id.$id }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success('success')
          this.$router.back(-1)
        }
      })
    },
    async getQiniuToken () {
      return new Promise((resolve) => {
        getQNToken().then((res) => {
          resolve(res)
        })
      })
    },
    async beforeAvatarUpload (file) {
      const result = await this.getQiniuToken()
      if (result.data.code === 0) {
        const token = result.data.data
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        )
        const observer = {
          complete: (res) => {
            this.form.cover_pic =
              'http://mainbucket.reachable-edu.com/' + res.key
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background: url('../../assets/applyBack.png');
  background-size: 110% 120%;
  background-position: 10% 10%;
  font-family: "DDINAlternateRegular";
  .content {
    width: 66%;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
    padding: 60px 70px;
    background: #fff;
    .title {
      color: #f65d4d;
      font-weight: bold;
      font-size: 20px;
    }
    .loginInputView {
      width: 50%;
      margin-top: 26px;
    }
    .confirm {
      margin: 20px auto;
      background: #0e4890;
      width: 300px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
    }
  }

  ::v-deep .el-input__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    height: 42px;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:hover {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner:hover {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
}
</style>
